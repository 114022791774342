import "./NpsScore.css";
import React, { useEffect, useState, useImperativeHandle, useRef } from "react";
import { useTranslation } from 'react-i18next';
import useQuery from "./useQuery";

const NpsScore = React.forwardRef(
  ({ enabled, value, onChange, onClickDisabled, showText = true }, ref) => {
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      clear() {
        setRating(-1);
        setActiveRating(-1);
      },

      setRating(rating) {
        setRating(rating);
        setActiveRating(rating);
      },
    }));

    const [activeRating, setActiveRating] = useState(-1);
    const [rating, setRating] = useState(-1);

    const timerRating = useRef(null);
    const query = useQuery();

    useEffect(() => {
      if (value) {
        setRating(value);
        setActiveRating(value);
      }
    }, [value]);

    useEffect(() => {
      if (onChange) onChange(rating);
    }, [rating]);

    useEffect(() => {
      var npsRating = query.get("npsRating");
      if (npsRating) {
        setRating(parseInt(npsRating));
        setActiveRating(parseInt(npsRating));
      }
    }, []);

    return (
      <div className="nps-score-container">
        <div className="nps-score-rating">
          {[...Array(11)].map((x, i) => {
            return (
              <button
                type="button"
                className={`score score-${i} ${
                  activeRating === i ? "active" : ""
                }`}
                onMouseOver={() => {
                  if (enabled) {
                    if (timerRating.current != null)
                      clearTimeout(timerRating.current);
                    timerRating.current = setTimeout(() => {
                      setActiveRating(i);
                    }, 60);
                  }
                }}
                onMouseOut={() => {
                  if (enabled) {
                    if (timerRating.current != null)
                      clearTimeout(timerRating.current);
                    timerRating.current = setTimeout(() => {
                      setActiveRating(rating);
                    }, 60);
                  }
                }}
                onClick={() => {
                  enabled
                    ? setRating(i)
                    : onClickDisabled && onClickDisabled(i);
                }}
              >
                {i}
              </button>
            );
          })}
        </div>

        <div className="legend">
          <div className="less-likely">{t('nps_less_likely')}</div>
          <div className="most-likely">{t('nps_most_likely')}</div>
        </div>
      </div>
    );
  }
);

export { NpsScore };