/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Ring } from "./Ring";
import { useTranslation } from 'react-i18next';

import "./UnsubscribePage.scss";
import loading from "./loading-inverse.gif";

export function UnsubscribePage(props) {
  const { t, i18n } = useTranslation();
  const { customer, notificationId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(false);
  const [sent, setSent] = useState(false);
  const [notificationInfo, setNotificationInfo] = useState(null);

  const getData = async () => {
    setIsLoading(true);

    var customerData = await axios.get(
      `${process.env.REACT_APP_API_URL}/${customer}/info`
    );

    setCustomerInfo(customerData.data);

    var notification = await axios.get(
      `${process.env.REACT_APP_API_URL}/${customer}/notifications/subscription/${notificationId}`
    );

    setNotificationInfo(notification.data);

    setIsLoading(false);
  };

  const sendOptOut = async () => {
    setIsSubmitting(true);
    await axios.post(
      `${process.env.REACT_APP_API_URL}/${customer}/notifications/optOut/${notificationId}`
    );
    setIsSubmitting(false);
    setSent(true);
  };

  useEffect(() => {
    getData();
  }, [customer, notificationId]);

    useEffect(() => {
      if (customerInfo?.settings?.locale) {
        i18n.changeLanguage(customerInfo.settings.locale)
      }
    }, [customerInfo])

  return (
    <div className="unsubscribe-page">
      <div className="logo">
        <img src={customerInfo.logo} alt={t('alt.customer_logo', { name: customerInfo.name })} />
      </div>
      <h1>{t('unsubscribe_title', { storeName: customerInfo.name })}</h1>

      {isLoading && (
        <div className="loading-container">
          <Ring />
        </div>
      )}

      {notificationInfo && (
        <>
          {!sent ? (
            <div className="unsubscribe-form">
              {notificationInfo.email && (
                <p>{t('email_label', { email: notificationInfo.email })}</p>
              )}
              {notificationInfo.phone && (
                <p>{t('unsubscribe_phone_label', { phone: notificationInfo.phone })}</p>
              )}

              <p>
                {t('unsubscribe_prompt', { method: notificationInfo.email ? t('email') : t('whatsapp') })}
              </p>

              <button
                className="btn-unsubscribe"
                onClick={() => {
                  sendOptOut();
                }}
              >
                {t('unsubscribe_button', { method: notificationInfo.email ? t('emails') : t('messages') })}
                {isSubmitting && <img alt={t('alt.loading')} src={loading} />}
              </button>
            </div>
          ) : (
            <div className="success-message">
              {t('unsubscribe_success')}
            </div>
          )}
        </>
      )}
    </div>
  );
}