import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en_US.json";
import pt from "./locales/pt_BR.json";
import es from "./locales/es.json";

const resources = {
  en_US: { translation: en },
  pt_BR: { translation: pt },
  es: { translation: es },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "pt_BR", // Idioma padrão
    fallbackLng: "pt_BR",
    interpolation: {
      escapeValue: false, // React já escapa valores
    },
    detection: {
      order: ["navigator", "htmlTag", "path", "subdomain"], // Ordem de detecção
      caches: ["localStorage", "cookie"], // Onde armazenar o idioma detectado
    },
    debug: true, // Para verificar no console qual idioma foi detectado
  });

export default i18n;
