/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import "./RateExternal.scss";

import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Ring } from "./Ring";
import { format } from "date-fns";
import { useTranslation } from 'react-i18next';

export function RateExternal() {
  const { t } = useTranslation();
  const { customer } = useParams();
  const [customerInfo, setCustomerInfo] = useState(null);
  const [document, setDocument] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shallValidate, setShallValidate] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [orderList, setOrderList] = useState(null);

  const navigate = useNavigate();

  const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999")
      return false;
    // Valida 1o digito	
    var add = 0;
    for (var i = 0; i < 9; i++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    // Valida 2o digito	
    add = 0;
    for (var i = 0; i < 10; i++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;
    return true;
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setFetchError(null);
    setIsSubmitting(true);
    var { data } = await axios.get(`${process.env.REACT_APP_API_URL}/${customer}/external-orders/find?document=${document.replace(/[^\d]+/g, '')}`);

    if (data.length == 0) {
      setFetchError(t('no_orders_found'));
    } else if (data.length == 1) {
      navigate(`/ext/${customer}/${data[0].items[0]._id}/${data[0].orderId}`);
    } else {
      setOrderList(data);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      var customerData = await axios.get(`${process.env.REACT_APP_API_URL}/${customer}/info`);
      setCustomerInfo(customerData.data);
      setIsLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    setFetchError(null);
    if (document.length < 14) {
      setIsValid(false);
      setValidationError(t('enter_cpf'));
      return;
    } else {
      setShallValidate(true);
    }

    if (!validarCPF(document)) {
      setIsValid(false);
      setValidationError(t('invalid_cpf'));
      return;
    }

    setIsValid(true);
  }, [document]);

  return (
    <div className="rate-external-page">
      {customerInfo && (
        <>
          {customerInfo.importCssWebApp && (
            <link rel="stylesheet" href={customerInfo.importCssWebApp} />
          )}

          <div className="logo">
            {customerInfo && (
              <img src={customerInfo.logo} alt={t('alt.customer_logo', { name: customerInfo.name })} />
            )}
          </div>
          {isLoading && (
            <div className="loading-container">
              <Ring />
            </div>
          )}
          {!isLoading && !orderList && (
            <>
              <form onSubmit={handleSubmit}>
                <div className="info">
                  {t('enter_cpf_instruction')}
                </div>
                <input
                  type="text"
                  className="document"
                  onBlur={() => { setShallValidate(true); }}
                  onChange={(evt) => { setDocument(cpfMask(evt.target.value)); }}
                  value={document}
                />
                <button type="submit" disabled={!isValid || isSubmitting}>
                  {!isSubmitting ? t('search_order') : t('searching_order')}
                  {isSubmitting && <Ring />}
                </button>
                {!isValid && shallValidate && (<div className="error">{validationError}</div>)}
                {fetchError && (<div className="error">{fetchError}</div>)}
              </form>
            </>
          )}

          {!isLoading && orderList && (
            <>
              <div className="info">
                <p>{t('orders_found', { count: orderList.length })}</p>
                <p>{t('choose_order_to_rate')}</p>
              </div>
              <div className="orders">
                {orderList.map((order) => {
                  return (
                    <Link to={`/ext/${customer}/${order.items[0]._id}/${order.orderId}`} key={order.orderId}>
                      <div className="order">
                        <div className="order-id">{t('order_id', { id: order.orderId })}</div>
                        <div className="order-date">{t('order_date', { date: format(new Date(order.createdDate), "dd/MM/yyyy HH:mm:ss") })}</div>
                        <div className="products">
                          {order.items.length} {order.items.length > 1 ? t('products') : t('product')}
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}