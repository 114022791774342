/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import InputMask from "react-input-mask";
import { useTranslation } from 'react-i18next';

import "./RateProduct.scss";
import backButton from "./left_arrow.png";
import closeButton from "./close_button.png";
import loading from "./loading-inverse.gif";

import { StarsRating } from "./StarsRating";
import { Ripple } from "./Ripple";
import useQuery from "./useQuery";

export function RateProduct(props) {
  const { t } = useTranslation();
  const { show, product, customerInfo, initialRating, external } = props;
  const onHideProps = props.onHide;
  const onComplete = props.onComplete;
  const { modal } = props;
  const [rating, setRating] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [explicitConsent, setExplicitConsent] = useState(false);
  const [isLoadingCompleteness, setIsLoadingCompleteness] = useState(false);
  const [attributesSent, setAttributesSent] = useState(false);
  const [isSendEnabled, setIsSendEnabled] = useState(false);
  const [isSendAttributesEnabled, setIsSendAttributesEnabled] = useState(false);
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [recommended, setRecommended] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const [availableAttributes, setAvailableAttributes] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);
  const [idReview, setIdReview] = useState(null);
  const [updateTokenId, setUpdateTokenId] = useState(null);
  const [uploadError, setUploadError] = useState(null);

  const query = useQuery();
  const starsRef = useRef();
  const timerDebounce = useRef();
  const [topics, setTopics] = useState([]);
  const [topicsCompleteness, setTopicsCompleteness] = useState([]);

  const getTopics = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/${product?.customer}/${product?.sku}/topics`
    );
    const topicsResult = result.data.topics
      ? result.data.topics.slice(0, 3).map((item) => item.title)
      : [];
    setTopics(topicsResult);
  };

  function debounce(method, delay) {
    if (timerDebounce.current) clearTimeout(timerDebounce.current);
    timerDebounce.current = setTimeout(function () {
      method();
    }, delay);
  }

  useEffect(() => {
    if (!show) {
      starsRef && starsRef.current && starsRef.current.clear();
      setRating(0);
      setText("");
      setFiles([]);
      setImages([]);
      setActiveStep(1);
      setAvailableAttributes([]);
      setIdReview(null);
      setName("");
      setPhone("");
      setVideo(null);
      setUploadError(null);
    } else {
      if (initialRating)
        starsRef &&
          starsRef.current &&
          starsRef.current.setRating(initialRating);

      window.gtag("event", "rateProduct", {
        customer: product?.customer,
        sku: product?.sku,
      });

      getTopics();
    }
  }, [show]);

  useEffect(() => {
    if (!modal) {
      getTopics();
    }
  }, []);

  useEffect(() => {
    setIsSendAttributesEnabled(attributeValues.length > 0 && !isLoading);
  }, [attributeValues, isLoading]);

  useEffect(() => {
    debounce(() => {
      checkCompleteness(text);
    }, 1500);
  }, [text]);

  useEffect(() => {
    setIsSendEnabled(
      rating > 0 &&
        (!customerInfo?.settings?.requireText || text !== "") &&
        recommended !== "" &&
        (product.orderId ||
          (name !== "" &&
            name.length >= 3 &&
            phone !== "" &&
            phone.length === 13)) &&
        !isLoading &&
        (images.length === 0 ||
          !customerInfo?.settings?.explicitConsent ||
          explicitConsent)
    );
  }, [rating, recommended, isLoading, name, phone, explicitConsent, text]);

  useEffect(() => {
    if (rating > 0)
      window.gtag("event", "setRating", {
        customer: product?.customer,
        sku: product?.sku,
        rating,
      });

    if (rating >= 3) setRecommended(true);
    else if (rating > 0) setRecommended(false);
    else setRecommended("");
  }, [rating]);

  const checkCompleteness = async (text) => {
    const data = {
      topics,
      text,
    };

    if (product)
      window.gtag("event", "changeText", {
        customer: product?.customer,
        sku: product?.sku,
        textLength: text.length,
        hasTopics: topics.length > 0,
      });

    if (text.trim() === "" || text.trim().length < 10 || topics.length === 0) {
      setTopicsCompleteness([]);
      return;
    }

    setIsLoadingCompleteness(true);
    var result = await axios.post(
      `${process.env.REACT_APP_API_URL}/${product?.customer}/${product?.sku}/completeness`,
      data
    );

    setTopicsCompleteness(result.data);
    setIsLoadingCompleteness(false);
  };

  const previewFiles = (evt) => {
    const file = evt.target.files[0];

    if (file.type.includes("image")) {
      window.gtag("event", "sendPicture", {
        customer: product?.customer,
        sku: product?.sku,
      });

      setFiles([...files, evt.target.files[0]]);
      setImages([...images, URL.createObjectURL(file)]);

      setUploadError(null);
    } else if (video == null) {
      window.gtag("event", "sendVideo", {
        customer: product?.customer,
        sku: product?.sku,
      });

      const url = URL.createObjectURL(file);
      console.log("url", url);

      setFiles([...files, evt.target.files[0]]);
      setVideo(url);

      setUploadError(null);
    } else {
      setUploadError(t('errors.video_limit'));
    }

    evt.target.files = null;
    evt.target.value = "";
  };

  const onHide = () => {
    window.gtag("event", "cancelRating", {
      customer: product?.customer,
      sku: product?.sku,
    });
    if (
      activeStep !== 1 ||
      window.confirm(t('confirm_cancel'))
    )
      onHideProps();
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (!isSendEnabled) return;

    setIsLoading(true);

    window.gtag("event", "sendReview", {
      customer: product?.customer,
      sku: product?.sku,
      hasText: text.length > 0,
      hasPicture: images.length > 0,
      hasVideo: video != null,
      hasTopics: topics.length > 0,
      textLength: text.length,
      topicsCompleteness:
        topicsCompleteness.filter((x) => !!x).length / topics.length,
    });

    try {
      let data = {
        orderId: product.orderId,
        verificationTokenId: !external ? product._id : null,
        externalItemId: external ? product._id : null,
        name: name,
        phone: phone,
        rating: rating,
        text: text,
        recommended: recommended,
        userId: product.userId,
        source: !external ? query.get("source") ?? "web" : "external",
      };

      let result = await axios.post(
        `${process.env.REACT_APP_API_URL}/${product?.customer}/${product?.sku}/review`,
        data
      );

      if (result && result.data) {
        if (onComplete) onComplete();
        var {
          updateToken,
          _id: reviewId,
          availableAttributes: attributes,
        } = result.data;
        setAvailableAttributes(attributes);
        setUpdateTokenId(updateToken);
        setIdReview(reviewId);

        if (images.length > 0) {
          let data = new FormData();
          data.append("updateToken", updateToken);

          let i = 0;
          for (var key in files) {
            data.append("file" + i, files[key]);
            i++;
          }

          await axios.post(
            `${process.env.REACT_APP_API_URL}/${product?.customer}/${product?.sku}/review/${reviewId}/pictures`,
            data
          );
        }

        if (video != null) {
          let data = new FormData();
          data.append("updateToken", updateToken);
          const file = files.find((file) => file.type.includes("video"));
          data.append("file", file);
          await axios.post(
            `${process.env.REACT_APP_API_URL}/${product?.customer}/${product?.sku}/review/${reviewId}/video`,
            data
          );
        }

        setActiveStep(2);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleChangeMCAttributeValue = (evt) => {
    var attrId = evt.target.name;
    var attrValue = evt.target.value;

    var attribute = availableAttributes.find(x => x._id === attrId)
    var selectedValue = attribute.choices.findIndex(x => x === attrValue)

    if (evt.target.checked || evt.target.tagName.toUpperCase() === 'SELECT') {
      var attrValues = [...attributeValues];

      var elmIndex = attrValues.findIndex((attr) => {
        return attr._id === attrId;
      });

      if (elmIndex > -1 && selectedValue > -1) {
        attrValues[elmIndex].response = attrValue;
        attrValues[elmIndex].value = selectedValue;
      } else if (elmIndex == -1 && selectedValue > -1) {
        attrValues.push({
          _id: attrId,
          response: attrValue,
          value: selectedValue
        });
      } else if (elmIndex > -1 && selectedValue == -1) {
        attrValues.splice(elmIndex, 1)
      }

      console.log(attrValues)

      setAttributeValues(attrValues)
    }
  };

  const handleSubmitAttributes = async (evt) => {
    evt.preventDefault();

    window.gtag("event", "sendAttributes", {
      customer: product?.customer,
      sku: product?.sku,
    });

    setIsLoading(true);

    var body = {
      updateToken: updateTokenId,
      attributes: attributeValues,
    };

    await axios.post(
      `${process.env.REACT_APP_API_URL}/${product?.customer}/${product?.sku}/review/${idReview}/attributes`,
      body
    );
    setIsLoading(false);
    setAttributesSent(true);

    if (modal) onHide();
  };

  const removeImage = (i) => {
    var filesInst = [...files];
    var imagesInst = [...images];

    filesInst.splice(i, 1);
    imagesInst.splice(i, 1);

    setFiles(filesInst);
    setImages(imagesInst);
    setUploadError(null);
  };

  const removeVideo = () => {
    const images = files.filter((f) => f.type.includes("image"));
    setFiles(images);
    setVideo(null);
    setUploadError(null);
  };

  const completenessPercent =
    (topicsCompleteness.filter((x) => !!x).length / topics.length) * 100;

  const uploadMediaEnable = () => {
    var mediaTypes = [];

    if (!customerInfo?.disablePictures) {
      mediaTypes.push("image/jpeg", "image/png", "image/webp");
    }

    if (customerInfo?.enableVideoReview) {
      mediaTypes.push(
        "video/mpeg",
        "video/ogg",
        "video/webm",
        "video/mp4",
        "video/quicktime"
      );
    }

    return mediaTypes.join(",");
  };

  const uploadText = () => {
    if (!customerInfo?.disablePictures && customerInfo?.enableVideoReview) {
      return t('upload.both');
    } else if (customerInfo?.enableVideoReview) {
      return t('upload.video');
    } else {
      return t('upload.photo');
    }
  };

  return (
    <>
      {modal && (
        <div
          className={`rate-product-overlay ${show ? "show" : ""}`}
          onClick={() => {
            onHide();
          }}
        ></div>
      )}
      <div
        className={`rate-product ${modal ? "modal" : ""} ${
          show || !modal ? "show" : ""
        } ${product?.isStore ? "store" : ""}`}
      >
        {modal && (
          <>
            {activeStep === 1 && (
              <button
                className="back-button"
                onClick={() => {
                  onHide();
                }}
              >
                <img alt={t('alt.back')} src={backButton} />
              </button>
            )}
            {activeStep === 2 && (
              <button
                className="close-button"
                onClick={() => {
                  onHide();
                }}
              >
                <img alt={t('alt.close')} src={closeButton} />
              </button>
            )}
          </>
        )}
        <div className="step1">
          {product && activeStep === 1 && (
            <>
              <div className="product-details">
                {product.product?.image && (
                  <img
                    alt={t('alt.product_image', { name: product.product?.name })}
                    src={product.product?.image}
                  />
                )}
                {product.logo && (
                  <img alt={t('alt.product_image', { name: product.product?.name })} src={product.logo} />
                )}
                <div className="product-name">
                  {product.isStore &&
                    t('store_rating', { storeName: product.name })}

                  {!product.isStore &&
                    (product.product?.name ?? t('ref', { sku: product?.sku }))}
                </div>
              </div>

              <form className="form-review" onSubmit={handleSubmit}>
                <div className="label">{t('rating_label')}</div>
                <StarsRating
                  enabled={true}
                  ref={starsRef}
                  onChange={(rating) => {
                    setRating(rating);
                  }}
                />
                {!product.isStore && (
                  <>
                    <div className="label">
                      {t('recommendation_label', { productName: product.product?.name ?? t('product') })}
                    </div>
                    <div className="options">
                      <label className="option">
                        <input
                          type="radio"
                          name="recommend"
                          checked={recommended}
                          onChange={(evt) => {
                            setRecommended(evt.target.checked);
                          }}
                        />{" "}
                        {t('recommend_yes')}
                      </label>
                      <label className="option">
                        <input
                          type="radio"
                          name="recommend"
                          checked={recommended === false}
                          onChange={(evt) => {
                            setRecommended(!evt.target.checked);
                          }}
                        />{" "}
                        {t('recommend_no')}
                      </label>
                    </div>
                  </>
                )}
                <div className="label">
                  {product.isStore
                    ? t('comment_label_store')
                    : t('comment_label_product', { productName: product.product?.name ?? t('product') })}
                </div>
                {topics.length === 0 && (
                  <span className="description">
                    {t('comment_tip')}
                    {customerInfo?.settings?.requireText && ` ${t('required_field')}`}
                  </span>
                )}

                <div className="text-container">
                  <textarea
                    className="review-text"
                    value={text}
                    onChange={(evt) => {
                      setText(evt.target.value);
                    }}
                  ></textarea>
                  {isLoadingCompleteness && (
                    <div className="loading-icon">
                      <Ripple />
                    </div>
                  )}
                  {topics.length > 0 && (
                    <div className="completeness">
                      <span className="completeness-label">
                        {t('completeness_label')}
                      </span>
                      <div className="completeness-topics">
                        {topics.map((topic, index) => {
                          return (
                            <div
                              key={index}
                              className={`completeness-topic ${
                                topicsCompleteness[index] ? "complete" : ""
                              }`}
                            >
                              {topic}
                            </div>
                          );
                        })}
                      </div>
                      <div className="completeness-progress">
                        <div
                          className={`indicator ${
                            completenessPercent > 99 ? "complete" : ""
                          }`}
                          style={{ width: completenessPercent + "%" }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>

                {!product.orderId &&
                  customerInfo &&
                  customerInfo.enableUnauthenticatedReviews && (
                    <>
                      <div className="label">{t('name_label')}</div>
                      <input
                        type="text"
                        className="input-text"
                        value={name}
                        onChange={(evt) => {
                          setName(evt.target.value);
                        }}
                      />

                      <div className="label">{t('phone_label')}</div>
                      <InputMask
                        type="tel"
                        mask="99 99999-9999"
                        maskChar={null}
                        value={phone}
                        onChange={(evt) => {
                          setPhone(evt.target.value);
                        }}
                      />
                    </>
                  )}
                {!product.isStore &&
                  (!customerInfo ||
                    !customerInfo.disablePictures ||
                    customerInfo.enableVideoReview) && (
                    <>
                      <div className="label">{uploadText()}</div>
                      <span className="description">
                        {t('upload_tip')}
                      </span>

                      <ul className="pictures">
                        {images && images.length > 0 && (
                          <>
                            {images.map((image, i) => {
                              return (
                                <li key={i}>
                                  <img alt={t('alt.photo', { number: i + 1 })} src={image} />
                                  <span
                                    className="close"
                                    onClick={() => {
                                      removeImage(i);
                                    }}
                                  >
                                    X
                                  </span>
                                </li>
                              );
                            })}
                          </>
                        )}
                        {video != null && (
                          <li>
                            <video src={video} height="60" width="60" />
                            <span
                              className="close"
                              onClick={() => {
                                removeVideo();
                              }}
                            >
                              X
                            </span>
                          </li>
                        )}
                        <li className="add">
                          <button
                            className="add-files"
                            onClick={(evt) => {
                              evt.preventDefault();
                              document.getElementById("files").click();
                            }}
                          />
                        </li>
                      </ul>

                      {uploadError && (
                        <div className="error">{uploadError}</div>
                      )}

                      {customerInfo?.settings?.consentText &&
                        images.length > 0 && (
                          <div className="customer-consent-pictures">
                            <label>
                              {customerInfo?.settings?.explicitConsent && (
                                <input
                                  type="checkbox"
                                  checked={explicitConsent}
                                  onChange={(e) => {
                                    setExplicitConsent(e.target.checked);
                                  }}
                                />
                              )}
                              {customerInfo?.settings?.consentText} 
                              <a
                                href={customerInfo?.settings?.consentLinkUrl}
                                target="_blank"
                              >
                                {customerInfo?.settings?.consentLinkText}
                              </a>
                            </label>
                          </div>
                        )}
                      <input
                        type="file"
                        className="file-upload"
                        id="files"
                        accept={uploadMediaEnable()}
                        onChange={previewFiles}
                      ></input>
                    </>
                  )}
                <button
                  type="submit"
                  className="button"
                  disabled={!isSendEnabled}
                >
                  {t('submit_review')}{" "}
                  {isLoading && <img alt={t('alt.loading')} src={loading} />}
                </button>
              </form>
            </>
          )}
        </div>
        <div className="step2">
          {activeStep === 2 && (
            <form className="form-attributes" onSubmit={handleSubmitAttributes}>
              <div className="thanks">
                <h4>{t('success_title')}</h4>
                <p>
                  {attributesSent
                    ? t('success_message_with_attributes')
                    : t('success_message')}
                </p>
              </div>
              {!attributesSent &&
                availableAttributes &&
                availableAttributes.length > 0 && (
                  <>
                    <div className="attributes">
                      <label>{t('attributes_label')}</label>
                      {availableAttributes.map((attribute) => {
                        return (
                          <div className="attribute" key={attribute._id}>
                            <div className="label attribute-label">{attribute.title}</div>
                            <div className="attribute-description">{attribute.description}</div>
                            {attribute.type === "stars" && (
                              <StarsRating
                                enabled={true}
                                onChange={(rating) => {
                                  window.gtag("event", "rateAttribute", {
                                    customer: product?.customer,
                                    sku: product?.sku,
                                    attribute: attribute._id,
                                    rating: rating,
                                  });

                                  if (rating === 0 || !rating) return;

                                  var attrValues = [...attributeValues];

                                  var elmIndex = attrValues.findIndex(
                                    (attr) => {
                                      return attr._id === attribute._id;
                                    }
                                  );

                                  if (elmIndex > -1) {
                                    attrValues[elmIndex].value = rating;
                                  } else {
                                    attrValues.push({
                                      _id: attribute._id,
                                      value: rating,
                                    });
                                  }

                                  setAttributeValues(attrValues);
                                }}
                              />
                            )}

                            {attribute.type === "multiple-choice" && (
                              <div className="choices">
                                {attribute.choices.length < 5 ? (
                                  <>
                                    {attribute.choices.map((item, index) => {
                                      var attrValues = [...attributeValues];
                                      var elmIndex = attrValues.findIndex(
                                        (attr) => {
                                          return attr._id === attribute._id;
                                        }
                                      );
                                      return (
                                        <label className="choice-label">
                                          <input
                                            type="radio"
                                            name={attribute._id}
                                            checked={
                                              attrValues[elmIndex]?.response ===
                                              item
                                            }
                                            value={item}
                                            onChange={
                                              handleChangeMCAttributeValue
                                            }
                                          />{" "}
                                          {item}
                                        </label>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <select
                                      name={attribute._id}
                                      value={attributeValues.find(x => x._id === attribute._id)?.response}
                                      onChange={handleChangeMCAttributeValue}
                                    >
                                      <option value="">{t('select_option')}</option>
                                      {attribute.choices.map((item, index) => {
                                        return (
                                          <option key={index}>{item}</option>
                                        );
                                      })}
                                    </select>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    <button type="submit" disabled={!isSendAttributesEnabled}>
                      {t('submit_attributes')}{" "}
                      {isLoading && <img alt={t('alt.loading')} src={loading} />}
                    </button>
                  </>
                )}
            </form>
          )}
        </div>
      </div>
    </>
  );
}