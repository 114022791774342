import './StarsRating.css';

import React, { useEffect, useState, useImperativeHandle } from "react";
import { useTranslation } from 'react-i18next';

const StarsRating = React.forwardRef(({ enabled, value, onChange, onClickDisabled, showText = true }, ref) => {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    clear() {
      setRating(0);
      setActiveRating(0);
    },
    setRating(rating) {
      setRating(rating);
      setActiveRating(rating);
    }
  }));

  const clickText = 'ontouchstart' in window ? t('touch') : t('click');
  const ratingText = () => {
    switch (activeRating) {
      case 0:
        return '';
      case 1:
        return t('rating.1');
      case 2:
        return t('rating.2');
      case 3:
        return t('rating.3');
      case 4:
        return t('rating.4');
      case 5:
        return t('rating.5');
      default:
        return '';
    }
  };

  const [activeRating, setActiveRating] = useState(0);
  const [rating, setRating] = useState(0);

  useEffect(() => {
    if (value) {
      setRating(value);
      setActiveRating(value);
    }
  }, [value]);

  useEffect(() => {
    if (onChange) onChange(rating);
  }, [rating]);

  return (
    <div className="rating-container">
      {enabled && (<span className="description">{t('rating_tip', { action: clickText })}</span>)}
      <div className="stars-rating">
        <button
          type="button"
          className={`star ${activeRating >= 1 ? 'active' : ''}`}
          onMouseOver={() => { enabled && setActiveRating(1) }}
          onMouseOut={() => { enabled && setActiveRating(rating) }}
          onClick={() => { enabled ? setRating(1) : onClickDisabled && onClickDisabled(1) }}>
          <img src="https://reviews.konfidency.com.br/img/star-icon.svg" alt={t('alt.star')} />
        </button>
        <button
          type="button"
          className={`star ${activeRating >= 2 ? 'active' : ''}`}
          onMouseOver={() => { enabled && setActiveRating(2) }}
          onMouseOut={() => { enabled && setActiveRating(rating) }}
          onClick={() => { enabled ? setRating(2) : onClickDisabled && onClickDisabled(2) }}>
          <img src="https://reviews.konfidency.com.br/img/star-icon.svg" alt={t('alt.star')} />
        </button>
        <button
          type="button"
          className={`star ${activeRating >= 3 ? 'active' : ''}`}
          onMouseOver={() => { enabled && setActiveRating(3) }}
          onMouseOut={() => { enabled && setActiveRating(rating) }}
          onClick={() => { enabled ? setRating(3) : onClickDisabled && onClickDisabled(3) }}>
          <img src="https://reviews.konfidency.com.br/img/star-icon.svg" alt={t('alt.star')} />
        </button>
        <button
          type="button"
          className={`star ${activeRating >= 4 ? 'active' : ''}`}
          onMouseOver={() => { enabled && setActiveRating(4) }}
          onMouseOut={() => { enabled && setActiveRating(rating) }}
          onClick={() => { enabled ? setRating(4) : onClickDisabled && onClickDisabled(4) }}>
          <img src="https://reviews.konfidency.com.br/img/star-icon.svg" alt={t('alt.star')} />
        </button>
        <button
          type="button"
          className={`star ${activeRating >= 5 ? 'active' : ''}`}
          onMouseOver={() => { enabled && setActiveRating(5) }}
          onMouseOut={() => { enabled && setActiveRating(rating) }}
          onClick={() => { enabled ? setRating(5) : onClickDisabled && onClickDisabled(5) }}>
          <img src="https://reviews.konfidency.com.br/img/star-icon.svg" alt={t('alt.star')} />
        </button>
      </div>
      {showText && (<div className="rating">{ratingText()}</div>)}
    </div>
  );
});

export { StarsRating };